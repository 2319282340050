@import '../../styles/colors.scss';

.super-fast {
  animation-duration: 300ms;
}

.prixa {
  &-question {
    &-image {
      text-align: center;
      margin-bottom: 40px;

      img {
        max-width: 280px;
        width: 240px;
      }
    }
  }

  &-info {
    &-icon {
      color: $secondary;
      border: 2px solid $secondary; 
      padding: 5px 10px;
      border-radius: 24px;
    }
  }
  
}