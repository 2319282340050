.prixa {
  &-cta {
    display: flex;
    margin-bottom: 12px;

    .cta {
      flex: 50%;
      position: relative;

      &:not(:last-child) {
        margin-right: 16px;
      }

      img {
        width: 100%;
      }

      .prixa-cta-title {
        position: absolute;
        bottom: 12px;
        right: 12px;
        width: 96px;
        text-align: right;

        span {
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
          line-height: 1.29;
          letter-spacing: normal;
          font-stretch: normal;
        }
      }
    }

    &-soon {
      opacity: .70;
      position: absolute;
      z-index: 1;
      background-color: #ffffff;
      height: 100%;
      width: 100%;
      bottom: 0;
    }

    .coming-soon {
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      position: absolute;
      top: 24px;
      left: 12px;
      right: 12px;
      border-radius: 8px;
    }

  }
}