body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overscroll-behavior: none;
}

a {
  text-decoration: none;
}

button{
  outline: none;
}

input {
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
}

.flex-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

// .full-width {
//   width: 100%;
// }

.text-center {
  text-align: center;
  align-items: center;
}

.prixa {
  &-result {
    &-content {
      padding: 40px;
      display: flex;
      flex-direction: column;
      background-color: #0146AB;

      span {
        color: #ffffff;
      }
    }
  }

  &-input {
      width:100%;
      border: 1px solid #AAAAAA;
      border-radius: 5px;
      padding: 15px 50px 15px 24px;
      box-sizing: border-box;
      font-size: 16px;
  }
}

::-webkit-scrollbar {
  display: none;
}

::-moz-scrollbars {
  display:none;
}


// Base style for container
.prixa {
  height: calc(100vh - 64px - 5px);
  width: 375px;
  margin-top: 69px;
  display: table;
  margin: 69px auto 0px auto;

  &.is-full-height {
    height: 100vh;
    margin-top: 0px;
  }

  & > .prixa-container {
    max-height: calc(100vh - 64px - 5px);
    height: max-content;
    overflow: scroll;
    padding: 40px 40px 60px 40px;
    max-width: calc(375px - 80px);
    display: table-cell;
    vertical-align: bottom;
  }

  .is-bottom {
    vertical-align: bottom;
  }

  .is-top {
    vertical-align: top;
  }

  .is-middle {
    vertical-align: middle;
  }

  .is-full {
    padding: 0;
  }

  .is-full-height {
    padding: 40px 40px 0 40px;
  }

  .is-center > * {
    max-width: max-content;
    margin: auto;
  }

  &-footer-button {
    display: table-footer-group;
    height: 64px;
  }

  &-right-button {
    text-align: right;
    margin-left: 40px;
    > * {
      margin-top: 20px;
    }
  }

  &-title {
    width: 81.36%;
    margin-bottom: 40px;
    &-sub {
      line-height: 16px !important;
      width: 91.67%;
    }
    &-long-sub {
      width: 108%;
      margin-bottom: 24px;
    }
  } 

  &-button-inline {
    margin: 1px -40px 0px -40px;
    display: flex;
    & > * {
      width: 50%;
    }
  }
}

.prixa-sidesheet > .prixa {
  height: calc(100vh - 64px - 5px);
  width: 100vw;
  margin-top: 69px;
  display: table;
}

.prixa-continue-navigation {
  position: absolute;
  height: 48px;
  border-bottom: 1px solid #AAAAAA29;
  display: flex;
  flex-direction: row;
  width: 375px;
  justify-content: space-around;
  
  .navigation {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #AAAAAA;
    font-size: 16px;
    cursor: pointer;
  }

  .navigation.active {
    border-bottom: 2.5px solid #0843AB;
    color: #0843AB;
    font-weight: bold;
  }
}

.prixa-lineonsides {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: #AAAAAA;

  &::before,
  &::after {
    content: '';
    border-top: 1px solid #AAAAAA29;
    margin: 0 10px 0 0;
    flex: 1 0 10px;
  }

  &::after {
    margin: 0 0 0 10px;
  }
}


#crisp-chatbox > div > div {
  width: 375px !important;
  top: 64px !important;
  bottom: 0 !important;
  position: fixed !important;
  box-shadow: none !important;
  left: calc((100vw - 320px) / 2) !important;
  height: calc(100vh - 64px) !important;
}

.prixa-profile-list {
  .list {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(112, 112, 112, .25);
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .title {
        color: #4C4F54;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.prixa-pop-container {
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  padding: 16px;
}

.prixa-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.prixa-flex-column {
  display: flex;
  flex-direction: column;
}

.prixa {
  &-timeline {
    .list {
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
    }
  }
}

.crisp-client { display: none; }

.Toastify__toast-container.Toastify__toast-container--top-center.sc-kGXeez.fAhnnC.prixa-design-kit-toast-container {
  top: 80px !important;
}