.prixa-textarea {
  width: 100%;
  border: 1px solid #AAAAAA;
  border-radius: 5px;
  font-size: 16px;
  padding: 16px 20px;
  box-sizing: border-box;
  line-height: 19px;
  resize: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: italic;
    color: #AAAAAA;
  }
}