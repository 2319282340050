// DAV
@media (min-height: 1919px) and (min-width: 1079px) {
  .prixa-container-bottom, .prixa-container {
    zoom: 2.7;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 360px) and (max-device-width : 480px) and (orientation : portrait) {
  .prixa { width: 100vw !important; max-width: 100vw !important; }
  .prixa-container { width: calc(100vw - 80px) !important; max-width: calc(100vw - 80px) !important; }
  .prixa-continue-navigation {
    width: 100vw;
  }
  #crisp-chatbox > div > div {
    width: 100vw !important;
    top: 64px !important;
    bottom: 0 !important;
    position: fixed !important;
    box-shadow: none !important;
    left: 0 !important;
    height: calc(100vh - 64px) !important;
  }
}

@media only screen and (max-device-width : 320px) and (orientation : portrait) {
  .prixa { height: 105vh !important; }
}