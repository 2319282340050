.prixa-sidesheet {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #FFFFFF;
  overflow: scroll;
  // animation: slide-up linear 0.20s;
  z-index: 4;
  left: 0;
  right: 0;
  
  & > .prixa-container {
    margin-top: 65px;
    max-width: 375px;
    padding-bottom: 0px;
  }

  &-content {
    padding: 40px;
  }
}
