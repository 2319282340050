.prixa-status {
  width: calc(375px - 80px);
  &-wrapper {
    text-align: center;
    display: inline-table;
    margin-bottom: 20px;
    &:not(:first-of-type):not(:nth-of-type(5)) {
      padding-left: 18px
    }
  }
}