.prixa-checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.prixa-checkbox-button {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #AAAAAA;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:checked {
    background-color: #44CD90;
    border: 1px solid #44CD90;

    &::after {
      font-family: FontAwesome;
      content: "\f00c";
      color: #FFFFFF;
      position: absolute;
    }
  }
}

.prixa-checkbox-label {
  font-size: 16px;
  color: #4C4F54;
  line-height: 24px;
}