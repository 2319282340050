.prixa-radio:not(:last-child) {
  margin-right: 8px;
}

.prixa-radio-button {
  opacity: 0;
  position: absolute;
}

.prixa-radio-label {
  cursor: pointer;
  font-size: 16px;
  color: #4C4F54;
  padding: 11px 18px;
  font-weight: initial;
  border-radius: 21px;
  border: 1px solid #aaaaaa;
  white-space: nowrap;
}

.prixa-radio-button:checked + .prixa-radio-label { 
  background-color: #0046AB; 
  color: #ffffff; 
  font-weight: bold;
  border: 1px solid #0046AB;
}