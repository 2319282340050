@import '../../styles/colors.scss';

.prixa {
  &-profile-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    margin: 24px 40px;
    size: 16px;
    line-height: 18px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
    height: 48px;
    cursor: pointer;

    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 16px;
    }
  }

  &-continue-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #0146AB;
    border-radius: 24px;
    margin: 24px 40px;
    color: #ffffff;
    size: 16px;
    line-height: 18px;
    font-weight: bold;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);

    .button {
      padding: 16px 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      cursor: pointer;

      &-separator {
        border-right: 1px solid #ffffff;
        margin: 8px 0px;
      }
    }
  }

  &-menu {

    &-list {
      border-bottom: 1px solid $border-color;
      padding: 20px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 64px;
    }

    &-icon {
      margin-right: 20px;
    }

    &-link {
      span {
        font-size: 16px;
      }
    }

    &-title {
      margin: 0px 40px;
    }
  }
}