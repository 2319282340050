.prixa-birtdate-calendar {
  .Calendar {
    box-shadow: none;
    width: 100%;
    .Calendar__day.-selected {
      border-radius: 8px;
      background: rgb(68, 205, 144);
      font-weight: bold;
    }

    .Calendar__yearText, .Calendar__monthText {
      color: #ffffff;
      font-weight: bold;
      background: rgb(68, 205, 144);
    }

    .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
      background: #0146AB;
    }
  }
}

#prixa-file-upload {
  color: transparent;
  width: 65px;
}
#prixa-file-upload::-webkit-file-upload-button {
  visibility: hidden;
}
#prixa-file-upload::before {
  content: 'Ganti Foto';
  width: 191px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #55b9e4;
}