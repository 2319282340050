.prixa {
  &-modal {
    background-color: beige;
    position: fixed;
    z-index: 10000000000;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(76, 79, 84, 0.6);

    &-content {
      background-color: #ffffff;
      border: 1px solid #888;
      position: absolute;
      bottom: 0;
      left: calc((100vw - 375px) / 2);
      right: 0;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      max-width: 375px !important; 

      .content {
        padding: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
      }
    }

    &-action-button {
      display: flex;
    }
  }

  &-cancel-button {
    background-color: #EEEEEE;
    color: #4c4f54;
  }
}

@media only screen and (min-device-width : 360px) and (max-device-width : 480px) and (orientation : portrait) {
  .prixa-modal-content { max-width: calc(100vw) !important; left: 0; }
}