@import '../../styles/colors.scss';

.carousel-dot {
  height: 8px;
  width: 8px;
  border-radius: 24px;
  background-color: #A5BDE1;
  padding: 0px;
  border: 0px;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.carousel-dot.active {
  background-color: $primary;
}

ul.react-multi-carousel-dot-list {
  all: unset;
  position: fixed;
  margin-top: 14px;
}

.react-multi-carousel-track  > li {
  all: unset !important;
  width: 295px !important;
}

@media only screen and (min-device-width : 360px) and (max-device-width : 480px) and (orientation : portrait) {
  .react-multi-carousel-track  > li {
    width: calc(100vw - 80px) !important;
  }
}