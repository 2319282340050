$primary: #0046AB;
$secondary: #55B9E4;
$dark: #4C4F54;
$muted: #707070;
$border-color: #CCCCCC;
$success: #44cd90;
$danger: #ff6e5d;
$disabled: #aaa;
$primaryLight: #a5bde1;
$secondaryLight: #c3e6f6;
$black: #4c4f54;
$white: #fff;
$default: #4c4f54;
$light: #fff;